export default {
  encryptionKey: process.env.VUE_APP_ENCRYPT_KEY ?? "MrNePrTl",

  //Live
  apiBaseURL: process.env.VUE_APP_BASEURL ?? "https://marineapi.chiplc.com/",
  flwLivePublicKey:
    process.env.VUE_APP_FLW_LIVE_PUBLIC_KEY ??
    "FLWPUBK-d9d52d459a7b8577add4ab47c5f15bf4-X",

  //Test
  // apiBaseURL: process.env.VUE_APP_TEST_BASEURL ?? "http://192.168.0.9:85/",
  // flwLivePublicKey:
  //   process.env.VUE_APP_FLW_TEST_PUBLIC_KEY ??
  //   "FLWPUBK_TEST-ed4c3675ef7f33e36f298c566d5a1e4e-X",

  //flwTestPublicKey: process.env.VUE_APP_FLW_TEST_PUBLIC_KEY ?? 'FLWPUBK_TEST-ed4c3675ef7f33e36f298c566d5a1e4e-X',
};
