import CryptoJS from 'crypto-js';
import $config from './config'


export class $Session {
  static setItem = (name, data) => {
    sessionStorage.setItem(name, CryptoJS.AES.encrypt(data, $config.encryptionKey).toString());
  };

  static getItem = (itemName) => {
    const bytes = CryptoJS.AES.decrypt(sessionStorage.getItem(itemName), $config.encryptionKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  static hasItem = (itemName) => sessionStorage.getItem(itemName) !== null;

  static removeItem = (itemName) => sessionStorage.removeItem(itemName);

  static clear = () => sessionStorage.clear();
}




