import { defineStore } from 'pinia'


export const useMainStore = defineStore('main', {
    state: () => {
        return {
            loading: false,
            pageLoading: false,
            isLoggedIn: false,

            status: {
                type: '',
                class: '',
                message: '',
            },

            user: {},

        }
    },
})