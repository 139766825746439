import { createRouter, createWebHistory } from "vue-router";
import { useMainStore } from "../stores/main";
// import { $Session } from "../helpers/storage";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("../views/Home.vue"),
      beforeEnter: () => {
        if (
          useMainStore().user.role === "Customer" ||
          !useMainStore().isLoggedIn
        ) {
          router.push("/login");
        }
      },
    },

    // Auth Routes //
    {
      path: "/login",
      name: "Login",
      component: () => import("../views/Login.vue"),
      // beforeEnter: (to, from) => {
      //   if (useMainStore().user.role !== "Customer") router.push(from.path);
      // },
    },

    {
      path: "/login/:admin",
      name: "AdminLogin",
      component: () => import("../views/Login.vue"),
    },

    {
      path: "/register",
      name: "Register",
      component: () => import("../views/Register.vue"),
    },

    {
      path: "/changepassword",
      name: "ChangePassword",
      component: () => import("../views/ForgotPassword.vue"),
    },

    // Auth Routes //

    // Admin Routes //

    {
      path: "/users",
      name: "Users",
      component: () => import("../views/admin/users/Index.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Admin") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/users/newuser",
      name: "NewUser",
      component: () => import("../views/admin/users/NewUser.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Admin") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/users/:id",
      name: "EditUser",
      component: () => import("../views/admin/users/_id.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Admin") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/roles",
      name: "Role",
      component: () => import("../views/admin/setup/roles/Index.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Admin") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/roles/newrole",
      name: "NewRole",
      component: () => import("../views/admin/setup/roles/NewRole.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Admin") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/roles/:id",
      name: "EditRole",
      component: () => import("../views/admin/setup/roles/_id.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Admin") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/rates",
      name: "Rate",
      component: () => import("../views/admin/setup/rates/Index.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Admin") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/rates/newrate",
      name: "NewRate",
      component: () => import("../views/admin/setup/rates/NewRate.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Admin") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/rates/:id",
      name: "EditRate",
      component: () => import("../views/admin/setup/rates/_id.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Admin") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/parkingtypes",
      name: "ParkingTypes",
      component: () => import("../views/admin/setup/parkingTypes/Index.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Admin") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/parkingtypes/newparkingtype",
      name: "NewParkingType",
      component: () =>
        import("../views/admin/setup/parkingTypes/NewParkingType.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Admin") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/parkingtypes/:id",
      name: "EditParkingType",
      component: () => import("../views/admin/setup/parkingTypes/_id.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Admin") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/voyagetypes",
      name: "VoyageTypes",
      component: () => import("../views/admin/setup/voyageTypes/Index.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Admin") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/voyagetypes/newvoyagetype",
      name: "NewVoyageType",
      component: () =>
        import("../views/admin/setup/voyageTypes/NewVoyageType.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Admin") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/voyagetypes/:id",
      name: "EditVoyageType",
      component: () => import("../views/admin/setup/voyageTypes/_id.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Admin") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/covertypes",
      name: "CoverTypes",
      component: () => import("../views/admin/setup/coverTypes/Index.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Admin") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/covertypes/newcovertype",
      name: "NewCoverType",
      component: () =>
        import("../views/admin/setup/coverTypes/NewCoverType.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Admin") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/covertypes/:id",
      name: "EditCoverType",
      component: () => import("../views/admin/setup/coverTypes/_id.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Admin") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/natureofcargos",
      name: "NatureOfCargos",
      component: () => import("../views/admin/setup/natureOfCargos/Index.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Admin") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/natureofcargos/newnatureofcargo",
      name: "NewNatureOfCargo",
      component: () =>
        import("../views/admin/setup/natureOfCargos/NewNatureOfCargo.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Admin") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/natureofcargos/:id",
      name: "EditNatureOfCargo",
      component: () => import("../views/admin/setup/natureOfCargos/_id.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Admin") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/conveyance",
      name: "Conveyances",
      component: () => import("../views/admin/setup/conveyances/Index.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Admin") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/conveyance/newconveyance",
      name: "NewConveyance",
      component: () =>
        import("../views/admin/setup/conveyances/NewConveyance.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Admin") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/conveyance/:id",
      name: "EditConveyance",
      component: () => import("../views/admin/setup/conveyances/_id.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Admin") {
          router.push(from.path);
        }
      },
    },

    // Admin Routes //

    // App Routes //

    {
      path: "/broker/buymarine",
      redirect: "/broker/buymarine/step-1",
      name: "BuyMarine",
      component: () => import("../views/broker/BuyMarine.vue"),
      children: [
        {
          path: "/broker/buymarine/step-1",
          name: "StepOne",
          component: () => import("../views/broker/marine/steps/StepOne.vue"),
        },
        {
          path: "/broker/buymarine/step-2",
          name: "StepTwo",
          component: () => import("../views/broker/marine/steps/StepTwo.vue"),
        },
        {
          path: "/broker/buymarine/step-3",
          name: "StepThree",
          component: () => import("../views/broker/marine/steps/StepThree.vue"),
        },
        {
          path: "/broker/buymarine/payment/:id",
          name: "StepFour",
          component: () => import("../views/broker/marine/steps/StepFour.vue"),
        },
      ],
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Broker") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/broker/allBookedBusinesses",
      name: "BookedBusinesses",
      component: () => import("../views/broker/marine/Index.vue"),
      beforeEnter: (to, from, next) => {
        if (useMainStore().user.role == "Broker") {
          next();
        } else {
          next(from.path);
        }
      },
    },

    {
      path: "/broker/viewBusiness/:id",
      name: "BookedBusiness",
      component: () => import("../views/broker/marine/_id.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Broker") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/agent/buymarine",
      redirect: "/agent/buymarine/step-1",
      name: "AgentBuyMarine",
      component: () => import("../views/agent/BuyMarine.vue"),
      children: [
        {
          path: "/agent/buymarine/step-1",
          name: "AgentStepOne",
          component: () => import("../views/agent/marine/steps/StepOne.vue"),
        },
        {
          path: "/agent/buymarine/step-2",
          name: "AgentStepTwo",
          component: () => import("../views/agent/marine/steps/StepTwo.vue"),
        },
        {
          path: "/agent/buymarine/step-3",
          name: "AgentStepThree",
          component: () => import("../views/agent/marine/steps/StepThree.vue"),
        },
        {
          path: "/agent/buymarine/payment/:id",
          name: "AgentStepFour",
          component: () => import("../views/agent/marine/steps/StepFour.vue"),
        },
      ],
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Agent") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/agent/allBookedBusinesses",
      name: "AgentBookedBusinesses",
      component: () => import("../views/agent/marine/Index.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Agent") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/agent/viewBusiness/:id",
      name: "AgentBookedBusiness",
      component: () => import("../views/agent/marine/_id.vue"),
      beforeEnter: (to, from) => {
        if (useMainStore().user.role !== "Agent") {
          router.push(from.path);
        }
      },
    },

    {
      path: "/customer/buymarine",
      redirect: "/customer/buymarine/step-1",
      name: "CustomerBuyMarine",
      component: () => import("../views/customer/BuyMarine.vue"),
      children: [
        {
          path: "/customer/buymarine/step-1",
          name: "CustomerStepOne",
          component: () => import("../views/customer/marine/steps/StepOne.vue"),
        },
        {
          path: "/customer/buymarine/step-2",
          name: "CustomerStepTwo",
          component: () => import("../views/customer/marine/steps/StepTwo.vue"),
        },
        {
          path: "/customer/buymarine/step-3",
          name: "CustomerStepThree",
          component: () =>
            import("../views/customer/marine/steps/StepThree.vue"),
        },
      ],
    },

    {
      path: "/customer/allBookedBusinesses",
      name: "CustomerBookedBusinesses",
      component: () => import("../views/customer/marine/Index.vue"),
    },

    {
      path: "/customer/viewBusiness/:id",
      name: "CustomerBookedBusiness",
      component: () => import("../views/customer/marine/_id.vue"),
    },

    // App Routes //
  ],
});

export default router;
