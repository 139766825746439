import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import "./helpers/logger";
import { logger } from "./helpers/persist";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { setupTimers } from "./helpers";

const pinia = createPinia();
pinia.use(logger);

// setupTimers()

createApp(App)
  .use(pinia)
  .use(router)
  .use(setupTimers)
  .component("v-select", vSelect)
  .mount("#app");
