<template>
  <router-view />
</template>

<script>
import { useMainStore } from "@/stores/main";
import { mapState } from "pinia";
export default {
  name: "App",
  computed: {
    ...mapState(useMainStore, ["isLoggedIn", "user"]),
  },

  created() {
    // if (!this.isLoggedIn) {
    //   this.$router.push("/login");
    // }
  },
};
</script>
