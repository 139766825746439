import { ToWords } from "to-words";
import { jsPDF } from "jspdf";

export class $toWords {
    static convert(num) {
        const toWords = new ToWords({
            localeCode: "en-GB",
            converterOptions: {
                currency: false,
                ignoreDecimal: false,
                ignoreZeroCurrency: false,
                doNotAddOnly: false,
            },
        });
        return toWords.convert(num)
    }

}

export class $savePdf {
    static save(html, num) {
        const doc = new jsPDF('p', 'pt', 'a4');
        doc.html(html, {
            'x': 10,
            'y': 10,
            'width': 150,
            callback: function (pdf) {
                pdf.save("CHIMarineCertificate(" + num + ").pdf");
            }
        });

    }

}

export function print(content) {
    document.body.innerHTML = content;
    window.print();
    location.reload();
}


let timeoutInMiliseconds = 900000;
let timeoutId; 
 
function startTimer() { 
    // window.setTimeout returns an Id that can be used to start and stop a timer
    timeoutId = window.setTimeout(doInactive, timeoutInMiliseconds)
}
 
function doInactive() {
    if(sessionStorage.getItem('access-token')){
        sessionStorage.clear()
    window.location.reload()
    }
    
}

function resetTimer() { 
    window.clearTimeout(timeoutId)
    startTimer();
}

export function setupTimers () {
    document.addEventListener("mousemove", resetTimer, false);
    document.addEventListener("mousedown", resetTimer, false);
    document.addEventListener("keypress", resetTimer, false);
    document.addEventListener("touchmove", resetTimer, false);
     
    startTimer();
}



