import { watch } from "vue";
import { $Session } from "../helpers/storage";
import { useMainStore } from "../stores/main";

export function logger() {
  const mainStore = useMainStore();
  if ($Session.hasItem("access-token")) {
    mainStore.user = JSON.parse($Session.getItem("user"));
    mainStore.isLoggedIn = true;
  }

  watch(
    () => mainStore.user,
    (state) => {
      if ($Session.hasItem("access-token")) {
        $Session.setItem("user", JSON.stringify(state));
      }
    },
    { deep: true }
  );
}
